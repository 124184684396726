export const DEFAULT_THEME_COLOR = '#36c9a2';

export const DEFAULT_THEME_COLOR_LIGHT = '#6ce1c6';

export const DEFAULT_THEME_COLOR_GREY = '#f3f3f3';

export const DEFAULT_BORDER_COLOR = '#36c9a2';

export const DEFAULT_BACKGROUND_COLOR = 'white';

export const FREAK_HUB_COLOR = '#F97300';
export const FEED_DAILY_COLOR = '#c936a2';
export const FEED_WEEKLY_COLOR = '#36c978';
export const FEED_MONTHLY_COLOR = '#36a2c9';
export const FEED_YEARLY_COLOR = '#c9364d';
