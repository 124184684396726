import {
  APP_LOAD,
  REDIRECT,
  LOGOUT,
  ARTICLE_SUBMITTED,
  SETTINGS_SAVED,
  LOGIN,
  REGISTER,
  DELETE_ARTICLE,
  DELETE_FREAK,
  FINISH_FREAK,
  CANCEL_FREAK,
  ARTICLE_PAGE_UNLOADED,
  EDITOR_PAGE_UNLOADED,
  HOME_PAGE_UNLOADED,
  PROFILE_PAGE_UNLOADED,
  PROFILE_FAVORITES_PAGE_UNLOADED,
  SETTINGS_PAGE_UNLOADED,
  LOGIN_PAGE_UNLOADED,
  REGISTER_PAGE_UNLOADED,
  SETUP_PASSWORD_UNLOADED,
  RESET_PASSWORD_UNLOADED,
  UPLOAD_USER_IMAGE,
  FREAK_SUBMITTED,
  FREAK_PLACES_SUBMITTED,
  BOTTOM_NAVIGATION_LOADED,
  BOTTOM_NAVIGATION_UNLOADED,
  IS_LOADING,
  BOTTOM_NAVIGATION_SET_UNREAD_MESSAGES,
  BOTTOM_NAVIGATION_VISIBILITY,
  TOP_NAVIGATION_VISIBILITY,
  UPLOAD_USER_BACKGROUND_IMAGE,
  CONTEXT_CHANGE,
  DISTANCE_CHANGE,
  LATITUDE_CHANGE,
  LONGITUDE_CHANGE,
  PRIVATE_FREAK_PLACE_CHANGE,
  PUBLIC_FREAK_PLACE_CHANGE,
  DELETE_FREAK_PLACE,
  SORT_BY_LIKES_CHANGE,
  SORT_BY_DATE_CHANGE,
  FILTER_BY_NAME_CHANGE,
  FILTER_BY_DATE_FROM_CHANGE,
  FILTER_BY_STATUS_CHANGE,
  FILTER_BY_PARTICIPANT_CHANGE,
  BOTTOM_NAVIGATION_SET_POINTS,
  LEAVE_FREAK,
  FILTER_BY_DATE_TO_CHANGE,
  RECORDING_FREAK_ROUTE,
  THEME_MODE_CHANGE, LEFT_NAVIGATION_OPEN,
} from '../constants/actionTypes';

const defaultState = {
  appName: 'wild-off-road', // process.env.APP_NAME,
  token: null,
  viewChangeCounter: 0,
  bottomNavigationVisible: true,
  topNavigationVisible: true,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case APP_LOAD:
      return {
        ...state,
        token: action.token || null,
        // @todo mobile has very low quality...
        device: action.device === 'mobile' || action.device ==='tablet' ? 'tablet' : (action.device || 'desktop'),
        appLoaded: true,
        currentUser: action.payload ? action.payload.user : null,
        context:action.payload ? action.payload.user?.context : null,
      };
    case IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      }
    case REDIRECT:
      return { ...state, redirectTo: null };
    case LOGOUT:
      return { ...state, redirectTo: '/', token: null, currentUser: null };
    case ARTICLE_SUBMITTED:
      return {
        ...state,
        redirectTo: action.error ? null : `/article/${action.payload.article.slug}`
      };
    case FREAK_SUBMITTED:
      return {
        ...state,
        redirectTo: action.error ? null : `/freak/${action.payload.freak.slug}`
      };
    case FREAK_PLACES_SUBMITTED:
      return {
        ...state,
        redirectTo: action.error ? null : `/places`
      };
    case SETTINGS_SAVED:
      return {
        ...state,
        redirectTo: action.error ? null : '/',
        currentUser: action.error ? null : action.payload.user
      };
    case THEME_MODE_CHANGE:
      return {
        ...state,
        newThemeMode: action.payload.newThemeMode,
      }
    case LOGIN:
      return {
        ...state,
        redirectTo: action.error ? null : action.redirectTo,
        token: action.error ? null : action.payload.user.token,
        currentUser: action.error ? null : action.payload.user
      };
    case REGISTER:
      return {
        ...state,
        token: action.error ? null : action.payload.user.token,
        currentUser: action.error ? null : action.payload.user,
        errors: action.error ? action.payload.errors : null,
        redirectTo: action.error ? null : action.redirectTo,
      };
    case DELETE_ARTICLE:
      return { ...state, redirectTo: '/' };
    case DELETE_FREAK:
    case CANCEL_FREAK:
    case FINISH_FREAK:
    case LEAVE_FREAK:
      return { ...state, redirectTo: '/freak' };
    case DELETE_FREAK_PLACE:
      return { ...state, redirectTo: '/places' };
    case ARTICLE_PAGE_UNLOADED:
    case EDITOR_PAGE_UNLOADED:
    case HOME_PAGE_UNLOADED:
    case PROFILE_PAGE_UNLOADED:
    case PROFILE_FAVORITES_PAGE_UNLOADED:
    case SETTINGS_PAGE_UNLOADED:
    case LOGIN_PAGE_UNLOADED:
    case REGISTER_PAGE_UNLOADED:
      return { ...state, viewChangeCounter: state.viewChangeCounter + 1 };
    case SETUP_PASSWORD_UNLOADED:
      return {
        ...state,
        redirectTo: action.error ? null : '/login',
      }
    case RESET_PASSWORD_UNLOADED:
      return {
        ...state,
        redirectTo: action.error ? null : '/login',
      }
    case UPLOAD_USER_IMAGE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          image: action.payload.image,
        },
        inProgress: false,
      }
    case UPLOAD_USER_BACKGROUND_IMAGE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          backgroundImage: action.payload.backgroundImage,
        },
        inProgress: false,
      }
    case BOTTOM_NAVIGATION_LOADED:
      return {
        ...state,
        notificationsCount: action.payload && action.payload[0] ? action.payload[0].count : 0,
      };
    case BOTTOM_NAVIGATION_SET_POINTS:
      return {
        ...state,
        pointsCount: action.payload && action.payload[0] ? action.payload[0].points : 0,
      };
    case BOTTOM_NAVIGATION_SET_UNREAD_MESSAGES:
      return {
        ...state,
        unreadMessagesCount: action.payload && action.payload ? action.payload : 0,
      }
    case BOTTOM_NAVIGATION_UNLOADED:
      return {};
    case BOTTOM_NAVIGATION_VISIBILITY:
      return {
        ...state,
        bottomNavigationVisible: action.payload
      };
    case LEFT_NAVIGATION_OPEN:
      return {
        ...state,
        leftNavigationOpen: action.payload,
      }
    case TOP_NAVIGATION_VISIBILITY:
      return {
        ...state,
        topNavigationVisible: action.payload
      };
    case CONTEXT_CHANGE:
      return {
        ...state,
        context: action.payload
      };
    case DISTANCE_CHANGE:
      return {
        ...state,
        distanceFilter: action.payload,
      }
    case SORT_BY_LIKES_CHANGE:
      return {
        ...state,
        sortByLikesFilter: action.payload,
      }
    case SORT_BY_DATE_CHANGE:
      return {
        ...state,
        sortByDateFilter: action.payload,
      }
    case FILTER_BY_NAME_CHANGE:
      return {
        ...state,
        filterByNameFilter: action.payload,
      }
    case FILTER_BY_DATE_FROM_CHANGE:
      return {
        ...state,
        filterByDateFromFilter: action.payload,
      }
    case FILTER_BY_DATE_TO_CHANGE:
      return {
        ...state,
        filterByDateToFilter: action.payload,
      }
    case FILTER_BY_STATUS_CHANGE:
      return {
        ...state,
        filterByStatusFilter: action.payload,
      }
    case FILTER_BY_PARTICIPANT_CHANGE:
      return {
        ...state,
        filterByParticipantFilter: action.payload,
      }
    case LATITUDE_CHANGE:
      return {
        ...state,
        latitudeFilter: action.payload,
      }
    case LONGITUDE_CHANGE:
      return {
        ...state,
        longitudeFilter: action.payload,
      }
    case PRIVATE_FREAK_PLACE_CHANGE:
      return {
        ...state,
        privateFreakPlaceFilter: action.payload,
      }
    case PUBLIC_FREAK_PLACE_CHANGE:
      return {
        ...state,
        publicFreakPlaceFilter: action.payload,
      }
    case RECORDING_FREAK_ROUTE:
      return {
        ...state,
        recordingFreakRoute: action.payload
      }
    default:
      return state;
  }
};
