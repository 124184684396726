import React from 'react';
import {connect} from "react-redux";

import {Card, CardMedia, Typography} from "@mui/material";
import CardContent from "@mui/joy/CardContent";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {getFreakIcon} from "../../utils/freakUtils";
import {createTheme, styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import {CONTEXT_CHANGE} from "../../constants/actionTypes";
import {useNavigate} from "react-router-dom";
import useTranslation from "../../customHooks/translations";
import {capitalizeFirstLetter} from "../../utils/commonUtils";
import {useMediaQuery} from "react-responsive";


const lightTheme = createTheme();

const Item = styled(Paper)(({theme}) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: 60,
  lineHeight: '60px',
}));

const ContextSelectViewComponent = (props) => {
  const navigate = useNavigate();
  const translation = useTranslation();
  const isDesktop = useMediaQuery({ minWidth: 992 });

  const handleChange = (value) => {
    props.onContextChange(value);

    navigate('/freak');
  };

  return (
    <Card sx={{
      height: '100vh',
      boxShadow: 'none',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      overflowY: 'auto',
      borderRadius: 0,
      paddingLeft: isDesktop && props.leftNavigationOpen ? 37 : 1,
      backgroundColor: 'transparent'
    }}>
      <CardContent
        sx={{
          marginLeft: 2,
          marginRight: 2,

        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 5,
          }}
        >
        </Box>
        <Grid container spacing={2}>
          {[lightTheme].map((theme, index) => (
            <Grid item xs={12} key={index}>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, 1fr)',
                  gap: 2,
                }}
              >
                {[
                  'wof',
                  'bike',
                  'fishing',
                  'trip',
                  'fit',
                  'running',
                  'dogs',
                  'games',
                  'books',
                  'food',
                  'shopping',
                  'tech',
                  'motorization',
                  'gardening',
                  'music',
                  'sport',
                  'winterSports',
                  'horseRiding',
                  'movies',
                  'series',
                ].map((elevation) => (
                  <Card
                    key={elevation}
                    sx={{
                      display: 'flex',
                      backgroundColor: '#333',
                      color: 'white',
                      borderRadius: 2,
                      width: '100%',   // Use 100% to take full drawer width
                      marginBottom: 1,
                      cursor: 'pointer',
                    }}
                    onClick={(event) => handleChange(elevation)}
                  >
                    <CardMedia
                      alt="elevation"
                      sx={{
                        width: 50,
                        height: 'auto',
                        objectFit: 'cover',
                        borderRadius: 2,
                        margin: 1,
                      }}
                    >
                      {getFreakIcon(elevation)}
                    </CardMedia>

                    <CardContent
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        height: 'auto',
                        width: 'calc(100% - 150px)',  // Adjust for the image width
                      }}
                    >
                      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {translation.settings[`context${capitalizeFirstLetter(elevation)}`]}
                      </Typography>
                    </CardContent>
                  </Card>
                ))}
              </Box>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  )
};

const mapStateToProps = state => ({
  ...state,
  currentUser: state.common.currentUser,
  context: state.common.context,
  leftNavigationOpen: state.common.leftNavigationOpen,
});

const mapDispatchToProps = dispatch => ({
  onContextChange: payload =>
    dispatch({type: CONTEXT_CHANGE, payload})
});

export default connect(mapStateToProps, mapDispatchToProps)(ContextSelectViewComponent);
