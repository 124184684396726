import React, { useState, useEffect } from 'react';
import './style.css';
import useTranslation from "../../customHooks/translations";
import {DEFAULT_THEME_COLOR} from "../../constants/theme";

const ShakeAndFlipImage = ({ isShaking: isShakingProp, price }) => {
  const translation = useTranslation();

  const [isFlipped, setIsFlipped] = useState(false);

  useEffect(() => {
    let shakeTimeout;
    let flipTimeout;

    if (isShakingProp) {
      // Trigger shaking animation
      shakeTimeout = setTimeout(() => {
        // Stop shaking and trigger flip
        flipTimeout = setTimeout(() => {
          setIsFlipped(true);
        }, 300); // Flip delay after shake
      }, 1000); // Shake duration
    }

    return () => {
      clearTimeout(shakeTimeout);
      clearTimeout(flipTimeout);
    };
  }, [isShakingProp]);

  return (
    <div className="flip-box">
      <div className={`flip-box-inner ${isFlipped ? 'flipped' : ''}`}>
        <div className="flip-box-front">
          <img
            src="./logo-circle.png"
            alt="shake"
            className={isShakingProp ? 'shaking' : ''}
          />
        </div>
        <div className="flip-box-back">
          {
            price
              ? <div>
                  <h3>{translation.shake.win}</h3>
                  <h2 style={{ color: DEFAULT_THEME_COLOR }}>{price}</h2>
                </div>
             : <h2>{translation.shake.loading}</h2>}
        </div>
      </div>
    </div>
  );
};

export default ShakeAndFlipImage;