import * as React from 'react';
import {connect} from 'react-redux';
import {useNavigate, useLocation} from "react-router-dom";
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import {DEFAULT_BACKGROUND_COLOR, DEFAULT_THEME_COLOR} from "../constants/theme";
import Avatar from "@mui/material/Avatar";
import {IconButton} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/material/Grid";
import ContextSwipeableDrawerComponent from "./ContextSwipeableDrawer/ContextSwipeableDrawerComponent";
import FilterSwipeableDrawerComponent from "./FiltersSwipeableDrawer/FilterSwipeableDrawerComponent";
import Button from "@mui/material/Button";
import {styled} from "@mui/material/styles";
import {Badge as BaseBadge, badgeClasses } from "@mui/base/Badge";
import { useTheme } from '@mui/material/styles';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import {useMediaQuery} from "react-responsive";

const Badge = styled(BaseBadge)(
  ({ theme }) => `
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-self: center;
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  line-height: 1;

  & .${badgeClasses.badge} {
    z-index: auto;
    position: absolute;
    top: 0;
    right: 0;
    min-width: 22px;
    height: 22px;
    padding: 0 0px;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    white-space: nowrap;
    text-align: center;
    border-radius: 12px;
    background: ${DEFAULT_THEME_COLOR};
    box-shadow: 0px 4px 6x #C7D0DD;
    transform: translate(50%, -50%);
    transform-origin: 100% 0;
  }

  & .${badgeClasses.invisible} {
    opacity: 0;
    pointer-events: none;
  }
  `,
);

function HideOnScroll(props) {
  const {children, window} = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const TopBar = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isDesktop = useMediaQuery({minWidth: 992});

  const isDarkMode = theme.palette.mode === 'dark';

  const currentUser = props?.common?.currentUser;

  return (
    props.topNavigationVisible && currentUser && !isDesktop
      ? <React.Fragment>
        <CssBaseline />
        <HideOnScroll {...props}>
          <AppBar sx={{
            backgroundColor: isDarkMode ? 'black' : DEFAULT_BACKGROUND_COLOR,
          }}>
            <Toolbar style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 'env(safe-area-inset-top, 50px)',
            }}>
              <Grid item xs display="flex" justifyContent="left" alignItems="left">
                <IconButton
                  color="primary"
                  aria-label="logo button"
                  sx={{
                    'button:focus': {
                      outline: 'none'
                    },
                    outline: 'none',
                    '&:focus': {
                      outline: 'none'
                    },
                    '&:hover': {
                      // color: 'black',
                      borderColor: 'white',
                    },
                  }}
                  onClick={() => props && props?.common?.token ? navigate(`/@${currentUser?.username}`) : navigate('/login')}
                >
                  <Avatar
                    alt={currentUser?.username}
                    src={currentUser?.imageUrl}
                  />
                </IconButton>
                <Button
                  variant="outlined"
                  style={{
                    color: 'grey',
                    borderColor: 'transparent',
                    outline: 'none',
                    '&:focus': {
                      outline: 'none'
                    },
                    '&:hover': {
                      // color: 'black',
                      borderColor: 'transparent',
                    },
                  }}
                  startIcon={
                    <Avatar
                      sx={{
                        width: 25,
                        height: 25,
                        marginLeft: 2,
                      }}
                      src={'./../icon-48x48.png'}
                    />
                  }
                  onClick={() => navigate('/top-freaks')}
                >
                  {props.pointsCount}F
                </Button>
              </Grid>
              <Grid item xs display="flex" justifyContent="right" alignItems="right">
                <ContextSwipeableDrawerComponent/>
                <Badge
                  badgeContent={props.unreadMessagesCount || 0}
                  color="primary"
                >
                  <IconButton
                    variant="outlined"
                    style={{
                      borderColor: 'white',
                    }}
                    sx={{
                      '&:focus': {
                        outline: 'none'
                      },
                      // color: 'black',
                    }}
                    onClick={() => navigate('/messages')}
                  >
                    <MailOutlineIcon />
                  </IconButton>
                </Badge>
                {
                  location?.pathname === '/freak' ||
                  location?.pathname === '/places'
                    ? <FilterSwipeableDrawerComponent pathname={location?.pathname}/>
                    : null
                }
                {
                  location?.pathname === '/freak' ||
                  location?.pathname === '/' ||
                  location?.pathname === '/places' ||
                  location?.pathname === '/freak-hub'
                    ? <IconButton
                      title="Add"
                      color="primary"
                      aria-label="logo button"
                      style={{
                        borderRadius: '50%',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                        backgroundColor: DEFAULT_THEME_COLOR,
                        color: 'white'
                      }}
                      onClick={() => {
                        if (location?.pathname === '/freak') {
                          navigate('/freak-editor');
                        } else if (location?.pathname === '/') {
                          navigate('/editor');
                        } else if (location?.pathname === '/places') {
                          navigate('/places-editor');
                        } else if (location?.pathname === '/freak-hub') {
                          navigate('/freak-hub-editor');
                        }
                      }}
                    >
                      <AddIcon/>
                    </IconButton>
                    : null
                }
              </Grid>
            </Toolbar>
          </AppBar>
        </HideOnScroll>
        <Toolbar/>
      </React.Fragment>
      : null
  );
};

const mapStateToProps = state => ({
  ...state,
  topNavigationVisible: state.common.topNavigationVisible,
  context: state.common.context,
  pointsCount: state.common.pointsCount,
  unreadMessagesCount: state.common.unreadMessagesCount,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
