import React, {useState} from 'react';
import {useMediaQuery} from "react-responsive";
import TopBar from "../TopBar";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {DEFAULT_THEME_COLOR} from "../../constants/theme";
import useTranslation from "../../customHooks/translations";
import ListHubsComponent from "./ListHubsComponent";
import {FREAK_HUB_TYPE_EXPLORE, FREAK_HUB_TYPE_MY} from "../../constants/commonConstants";

const FreakHubView = (props) => {
  const translation = useTranslation();
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <TopBar />
      <div className="home-page">
        <div
          className="container page"
          style={isDesktop ? {
            display: 'flex',
            justifyContent: 'center'
          } : {}}
        >
          <div
            className="row"
            style={isDesktop ? {
              maxWidth: 500,
            }: {}}
          >
            <div className="col-md-fullwidth">
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                aria-label="freakland-tabs"
                sx={{
                  '& .MuiTabs-indicator': {
                    backgroundColor: DEFAULT_THEME_COLOR,
                  },
                }}
              >
                <Tab
                  label={translation.tabs.myHubs}
                  sx={{
                    '&.Mui-selected': {
                      color: DEFAULT_THEME_COLOR,
                      'outline': 'none',
                    },
                  }}
                />
                <Tab
                  label={translation.tabs.exploreHubs}
                  sx={{
                    '&.Mui-selected': {
                      color: DEFAULT_THEME_COLOR,
                      'outline': 'none',
                    },
                  }}
                />
              </Tabs>

              {
                selectedTab === 0 && (<ListHubsComponent type={FREAK_HUB_TYPE_MY}/>)
              }

              {
                selectedTab === 1 && (<ListHubsComponent type={FREAK_HUB_TYPE_EXPLORE}/>)
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default FreakHubView;
