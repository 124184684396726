import superagent from 'superagent';

const API_ROOT = process.env.REACT_APP_API_ROOT;

const encode = encodeURIComponent;
const responseBody = res => res.body;

let token = null;
const tokenPlugin = req => {
  if (token) {
    req.set('authorization', `Bearer ${token}`);
  }
};

let username = null;
const usernamePlugin = req => {
  if (username) {
    req.set('X-username', username);
  }
};

const defaultDistance = 10000000;
const limit = (count, p) => `limit=${count}&offset=${p ? p * count : 0}`;
const omitSlug = article => Object.assign({}, article, { slug: undefined });

const requests = {
  del: url =>
    superagent.del(`${API_ROOT}${url}`).use(tokenPlugin).use(usernamePlugin).then(responseBody),
  get: url =>
    superagent.get(`${API_ROOT}${url}`).use(tokenPlugin).use(usernamePlugin).then(responseBody),
  put: (url, body) =>
    superagent.put(`${API_ROOT}${url}`, body).use(tokenPlugin).use(usernamePlugin).then(responseBody),
  post: (url, body) =>
    superagent.post(`${API_ROOT}${url}`, body).use(tokenPlugin).use(usernamePlugin).then(responseBody),
  patch: (url, body) =>
    superagent.patch(`${API_ROOT}${url}`, body).use(tokenPlugin).use(usernamePlugin).then(responseBody),
};

const Articles = {
  all: (page, device = 'desktop') =>
    requests.get(`/articles?${limit(10, page)}&device=${device}`),
  byAuthor: (author, page, device = 'desktop') =>
    requests.get(`/articles?author=${encode(author)}&${limit(5, page)}&device=${device}`),
  byTag: (tag, page, device = 'desktop') =>
    requests.get(`/articles?tag=${encode(tag)}&${limit(10, page)}&device=${device}`),
  del: slug =>
    requests.del(`/articles/${slug}`),
  favorite: slug =>
    requests.post(`/articles/${slug}/favorite`),
  favoritedBy: (author, page) =>
    requests.get(`/articles?favorited=${encode(author)}&${limit(5, page)}`),
  feed: (page, device = 'desktop') =>
    requests.get(`/articles/feed?${limit(5, page)}&device=${device}`),
  get: (slug, device = 'desktop') =>
    requests.get(`/articles/${slug}?device=${device}`),
  unfavorite: slug =>
    requests.del(`/articles/${slug}/favorite`),
  update: article =>
    requests.put(`/articles/${article.slug}`, { article: omitSlug(article) }),
  create: article =>
    requests.post('/articles', { article }),
};

const Auth = {
  current: () =>
    requests.get('/user'),
  login: (email, password) =>
    requests.post('/users/login', { user: { email, password } }),
  register: (username, email, password, token, context) =>
    requests.post('/users', { user: { username, email, password, token, context } }),
  resetPassword: (email, token) =>
    requests.post('/users/reset-password', { email, token }),
  setupPassword: (userId, token, password) =>
    requests.post(`/users/reset-password/${userId}/${token}`, { password }),
  confirmEmail: (userId, token) =>
    requests.post(`/users/confirm-email/${userId}/${token}`),
  save: user =>
    requests.put('/user', { user }),
  logout: () =>
    requests.get('/logout'),
};

const Comments = {
  createForArticle: (slug, comment) =>
    requests.post(`/articles/${slug}/comments`, { comment }),
  createForFreak: (slug, comment) =>
    requests.post(`/freaks/${slug}/comments`, { comment }),
  delete: (slug, commentId) =>
    requests.del(`/articles/${slug}/comments/${commentId}`),
  forArticle: slug =>
    requests.get(`/articles/${slug}/comments`),
  forFreak: slug =>
    requests.get(`/freaks/${slug}/comments`),
  forCampaign: slug =>
    requests.get(`/campaigns/${slug}/comments`),
};

const FreaksPlaces = {
  all: (context, showPublic, showPrivate, southWestLat, southWestLon, northEastLat, northEastLon) =>
    requests.get(`/freaks-places?context=${context}&showPublic=${showPublic}&showPrivate=${showPrivate}&southWestLat=${southWestLat}&southWestLon=${southWestLon}&northEastLat=${northEastLat}&northEastLon=${northEastLon}`),
  create: freakPlace =>
    requests.post('/freaks-places', { freakPlace }),
  update: freakPlace =>
    requests.patch(`/freaks-places/${freakPlace.slug}`, { freakPlace: omitSlug(freakPlace) }),
  del: freakPlaceId =>
    requests.del(`/freaks-places/${freakPlaceId}`),
  favorite: slug =>
    requests.post(`/freaks-places/${slug}/favorite`),
  unfavorite: slug =>
    requests.del(`/freaks-places/${slug}/favorite`),
  get: (slug, context, device = 'desktop') =>
    requests.get(`/freaks-places/${slug}?device=${device}&context=${context}`),
  sendForPublic: slug =>
    requests.post(`/freaks-places/${slug}/forPublic`),
};

const FreakActivities = {
  create: freakActivity =>
    requests.post('/freaks-activities', { freakActivity }),
  update: freakActivity =>
    requests.put(`/freaks-activities/${freakActivity.slug}`, { freakActivity: omitSlug(freakActivity) }),
};

const Campaigns = {
  all: (
    page,
    context,
    device = 'desktop',
    latitude,
    longitude,
    distance = defaultDistance,
    sortByLikes = 'none',
    sortByDate = 'none',
    filterByName = 'none',
    filterByDateFrom = 'none',
    filterByDateTo = 'none',
    filterByStatus = 'none',
    filterByParticipant = 'none',
  ) =>
    requests.get(`/campaigns?${limit(5, page)}&device=${device}&context=${context}&latitude=${latitude}&longitude=${longitude}&distance=${distance}&sortByLikes=${sortByLikes}&sortByDate=${sortByDate}&filterByName=${filterByName}&filterByDateFrom=${filterByDateFrom}&filterByDateTo=${filterByDateTo}&filterByStatus=${filterByStatus}&filterByParticipant=${filterByParticipant}`),
  create: campaign =>
    requests.post('/campaigns', { campaign }),
  update: campaign =>
    requests.put(`/campaigns/${campaign.slug}`, { campaign: omitSlug(campaign) }),
  favorite: slug =>
    requests.post(`/campaigns/${slug}/favorite`),
  unfavorite: slug =>
    requests.del(`/campaigns/${slug}/favorite`),
  start: campaignId =>
    requests.post(`/campaigns/${campaignId}/start`),
  finish: campaignId =>
    requests.post(`/campaigns/${campaignId}/finish`),
  get: (slug, device = 'desktop') =>
    requests.get(`/campaigns/${slug}?device=${device}`),
  requestContact: () =>
    requests.post('/campaigns/requestContact'),
};

const Feeds = {
  daily: (
    page,
    context,
    device = 'desktop',
    latitude,
    longitude,
    distance = defaultDistance,
    sortByLikes = 'none',
    sortByDate = 'none',
    filterByName = 'none',
    filterByDateFrom = 'none',
    filterByDateTo = 'none',
    filterByStatus = 'none',
    filterByParticipant = 'none',
    feedForUsername = '',
  ) =>
    requests.get(`/feed/daily?${limit(3, page)}&device=${device}&context=${context}&latitude=${latitude}&longitude=${longitude}&distance=${distance}&sortByLikes=${sortByLikes}&sortByDate=${sortByDate}&filterByName=${filterByName}&filterByDateFrom=${filterByDateFrom}&filterByDateTo=${filterByDateTo}&filterByStatus=${filterByStatus}&filterByParticipant=${filterByParticipant}&feedForUsername=${feedForUsername}`),
  createUserFeedForYouTube: forHandle =>
    requests.post(`/feed/youtube/${forHandle}`),
  list: (social, username) =>
    requests.get(`/feed/social/${social}/${username}`),
  disconnectSocial: socialId =>
    requests.post(`/feed/youtube/${socialId}/disconnect`),
  getTopFeeds: page =>
    requests.get(`/feed/top-feeds/top`),
};

const Freaks = {
  availableContexts: () => requests.get('/freaks/availableContexts'),
  all: (
    page,
    context,
    device = 'desktop',
    latitude,
    longitude,
    distance = defaultDistance,
    sortByLikes = 'none',
    sortByDate = 'none',
    filterByName = 'none',
    filterByDateFrom = 'none',
    filterByDateTo = 'none',
    filterByStatus = 'none',
    filterByParticipant = 'none',
  ) =>
    requests.get(`/freaks?${limit(3, page)}&device=${device}&context=${context}&latitude=${latitude}&longitude=${longitude}&distance=${distance}&sortByLikes=${sortByLikes}&sortByDate=${sortByDate}&filterByName=${filterByName}&filterByDateFrom=${filterByDateFrom}&filterByDateTo=${filterByDateTo}&filterByStatus=${filterByStatus}&filterByParticipant=${filterByParticipant}`),
  create: freak =>
    requests.post('/freaks', { freak }),
  update: freak =>
    requests.put(`/freaks/${freak.slug}`, { freak: omitSlug(freak) }),
  del: freakId =>
    requests.del(`/freaks/${freakId}`),
  cancel: freakId =>
    requests.post(`/freaks/${freakId}/cancel`),
  get: (slug, device = 'desktop') =>
    requests.get(`/freaks/${slug}?device=${device}`),
  feed: (
    page,
    context,
    device = 'desktop',
    latitude,
    longitude,
    distance = defaultDistance,
    sortByLikes = 'none',
    sortByDate = 'none',
    filterByName = 'none',
    filterByDateFrom = 'none',
    filterByDateTo = 'none',
    filterByStatus = 'none',
    filterByParticipant = 'none',
    feedForUsername = '',
  ) =>
    requests.get(`/freaks/feed?${limit(3, page)}&device=${device}&context=${context}&latitude=${latitude}&longitude=${longitude}&distance=${distance}&sortByLikes=${sortByLikes}&sortByDate=${sortByDate}&filterByName=${filterByName}&filterByDateFrom=${filterByDateFrom}&filterByDateTo=${filterByDateTo}&filterByStatus=${filterByStatus}&filterByParticipant=${filterByParticipant}&feedForUsername=${feedForUsername}`),
  followed: (
    page,
    context,
    device = 'desktop',
    latitude,
    longitude,
    distance = defaultDistance,
    sortByLikes = 'none',
    sortByDate = 'none',
    filterByName = 'none',
    filterByDateFrom = 'none',
    filterByDateTo = 'none',
    filterByStatus = 'none',
    filterByParticipant = 'none',
    feedForUsername = '',
  ) =>
    requests.get(`/freaks/followed?${limit(3, page)}&device=${device}&context=${context}&latitude=${latitude}&longitude=${longitude}&distance=${distance}&sortByLikes=${sortByLikes}&sortByDate=${sortByDate}&filterByName=${filterByName}&filterByDateFrom=${filterByDateFrom}&filterByDateTo=${filterByDateTo}&filterByStatus=${filterByStatus}&filterByParticipant=${filterByParticipant}&feedForUsername=${feedForUsername}`),
  forFreakHub: (
    page,
    context = 'wof',
    device = 'desktop',
    feedForFreakHub = '',
  ) =>
    requests.get(`/freaks/forFreakHub?${limit(3, page)}&device=${device}&context=${context}&feedForFreakHub=${feedForFreakHub}`),
  favorite: slug =>
    requests.post(`/freaks/${slug}/favorite`),
  favoritedBy: (author, page, context, device = 'desktop' ) =>
    requests.get(`/freaks?favorited=${encode(author)}&${limit(5, page)}&device=${device}&context=${context}`),
  unfavorite: slug =>
    requests.del(`/freaks/${slug}/favorite`),
  join: freakId =>
    requests.post(`/freaks/${freakId}/join`),
  start: freakId =>
    requests.post(`/freaks/${freakId}/start`),
  finish: freakId =>
    requests.post(`/freaks/${freakId}/finish`),
  publish: freakId =>
    requests.post(`/freaks/${freakId}/publish`),
  deleteParticipant: (freakId, participantId) =>
    requests.post(`/freaks/${freakId}/${participantId}/delete`),
  nearby: (latitude, longitude, distance = 100, context, device = 'desktop', sortBy= 'createdAt', sortOrder = 'desc') =>
    requests.get(`/freaks/nearby?latitude=${latitude}&longitude=${longitude}&distance=${distance}&device=${device}&sortBy=${sortBy}&sortOrder=${sortOrder}&context=${context}`),
  deleteYouTubeLink: (freakId, youTubeLinkId) =>
    requests.post(`/freaks/${freakId}/youtube/${youTubeLinkId}/delete`),
  deleteFacebookLink: (freakId, facebookLinkId) =>
    requests.post(`/freaks/${freakId}/facebook/${facebookLinkId}/delete`),
  deleteInstagramLink: (freakId, instagramLinkId) =>
    requests.post(`/freaks/${freakId}/instagram/${instagramLinkId}/delete`),
  reportAbuse: (slug, abuseReason) =>
    requests.post(`/freaks/${slug}/report-abuse/${abuseReason}`),
  getTopFreaks: page =>
    requests.get(`/freaks/top-freaks/top?${limit(10, page)}`),
  getMyPositionInTopFreaks: () =>
    requests.get('/freaks/top-freaks/my-position'),
  leave: (freakId, participantId) =>
    requests.post(`/freaks/${freakId}/leave/${participantId}`),
  updateRoute: (slug, freakRoute) =>
    requests.post(`/freaks/${slug}/updateRoute`, { freakRoute }),
};

const Notifications = {
  feed: page =>
    requests.get(`/notifications/feed?${limit(10, page)}`),
  count: () =>
    requests.get('/notifications/count'),
  read: notificationId =>
    requests.post(`/notifications/${notificationId}/read`),
  approve: notificationUrl =>
    requests.post(notificationUrl),
  decline: notificationUrl =>
    requests.post(notificationUrl),
  delete: (notificationId) =>
    requests.del(`/notifications/${notificationId}/delete`),
};

const Profile = {
  follow: username =>
    requests.post(`/profiles/${username}/follow`),
  get: username =>
    requests.get(`/profiles/${username}`),
  getAll: () =>
    requests.get('/profiles'),
  getAllForParticipants: () =>
    requests.get('/profiles/participants'),
  unfollow: username =>
    requests.del(`/profiles/${username}/follow`),
  getProfileFollowers: username =>
    requests.get(`/profiles/followers/${username}`),
  getProfileFollow: username =>
    requests.get(`/profiles/following/${username}`),
};

const Tags = {
  getAll: () => requests.get('/tags')
};

const Upload = {
  getPreSignedUrl: (path, fileSizeBytes) => requests.get(`/files/upload/pre-signed/${path}/${fileSizeBytes}`),
  createImagesForDevices: fileMetaData => requests.post('/files/upload/create-for-devices', { fileMetaData }),
};

const Images = {
  del: imageId => requests.del(`/images/${imageId}`),
};

const Chat = {
  getConfig: () => requests.get('/chat/config'),
  getToken: () => requests.get('/chat/authToken'),
};

const Firebase = {
  setFcmToken: fcmToken => requests.post(`/firebase/fcm/${fcmToken}`),
};

const Points = {
  count: () => requests.get('/points'),
};

const Shake = {
  check: () => requests.get('/shake-it'),
  shake: () => requests.post('/shake-it'),
};

const Price = {
  getHistory: page => requests.get(`/prices/history?${limit(10, page)}`),
};

const FreakHubs = {
  get: (slug, device = 'desktop') =>
    requests.get(`/freak-hubs/${slug}?device=${device}`),
  getUsers: (page, slug) =>
    requests.get(`/freak-hubs/${slug}/users?${limit(3, page)}`),
  explore: (
    page,
    context = 'wof',
    device = 'desktop',
  ) =>
    requests.get(`/freak-hubs/explore?${limit(3, page)}&device=${device}&context=${context}`),
  feed: (
    page,
    context = 'wof',
    device = 'desktop',
  ) =>
    requests.get(`/freak-hubs/feed?${limit(3, page)}&device=${device}&context=${context}`),
  getAll: (
    context = 'wof',
    device = 'desktop',
  ) => requests.get(`/freak-hubs/all?device=${device}&context=${context}`),
  deleteParticipant: (freakHubId, participantId) =>
    requests.post(`/freak-hubs/${freakHubId}/${participantId}/delete`),
  create: freakHub =>
    requests.post('/freak-hubs', { freakHub }),
  update: freakHub =>
    requests.patch(`/freak-hubs/${freakHub.slug}`, { freakHub: omitSlug(freakHub) }),
  join: freakHubId =>
    requests.post(`/freak-hubs/${freakHubId}/join`),
};

export default {
  Articles,
  Auth,
  Comments,
  Campaigns,
  Freaks,
  Feeds,
  FreaksPlaces,
  FreakActivities,
  Notifications,
  Profile,
  Tags,
  Upload,
  Images,
  Chat,
  Firebase,
  Points,
  Shake,
  Price,
  FreakHubs,
  setToken: _token => { token = _token; },
  getToken: () => token,
  setUserName: _username => { username = _username },
};
