import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {connect} from 'react-redux';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import agent from '../../agent';
import {
  NOTIFICATION_PAGE_LOADED,
  NOTIFICATION_PAGE_UNLOADED,
  NOTIFICATION_SET_PAGE,
} from '../../constants/actionTypes';
import {
  NOTIFICATION_OPERATION_TYPES,
  NOTIFICATION_TYPES
} from '../../constants/notificationConstants';
import {
  DEFAULT_THEME_COLOR,
  FEED_WEEKLY_COLOR,
  FEED_YEARLY_COLOR,
} from "../../constants/theme";
import TopBar from "../TopBar";
import useTranslation from "../../customHooks/translations";
import Grid from "@mui/material/Grid";
import {IconButton} from "@mui/material";
import {getFreakIcon} from "../../utils/freakUtils";
import {formatDateAgo} from "../../utils/dateUtils";
import InfiniteScroll from "react-infinite-scroller";
import CommonLoader from "../Loaders/CommonLoader";
import {useMediaQuery} from "react-responsive";

const getInvitationTypeName = (notificationType, translation) => {
  if (!notificationType) {
    return 'Error';
  }

  switch (notificationType) {
    case NOTIFICATION_TYPES.INVITATION:
      return translation.notifications.types.invitation;
    case NOTIFICATION_TYPES.INFO:
      return translation.notifications.types.info;
    case NOTIFICATION_TYPES.REQUEST_JOIN:
      return translation.notifications.types.requestJoin;
  }
};

const getNotificationOperationMessage = (notification, translation) => {
  if (notification?.status) {
    switch (notification.status) {
      case 'approved':
        return translation.freaks.participate.approved;
      case 'declined':
        return translation.freaks.participate.declined;
      default:
        return '';
    }
  }
};
const Notification = (props) => {
  const translation = useTranslation();
  const context = props.context;
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({ minWidth: 992 });

  const [notifications, setNotifications] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [pageStart, setPageStart] = useState(props.currentPage);

  const handleNotificationApproveClick = async (notification) => {
    const approveOperation = notification?.operations?.find(
      operation => operation.type === NOTIFICATION_OPERATION_TYPES.APPROVE
    );
    if (approveOperation) {
      await agent.Notifications.approve(approveOperation.operation_request);
      const response = await agent.Notifications.feed(0);
      setNotifications(response.notifications);
    }
  };

  const handleNotificationDeclineClick = async (notification) => {
    const declineOperation = notification?.operations?.find(
      operation => operation.type === NOTIFICATION_OPERATION_TYPES.DECLINE
    );
    if (declineOperation) {
      await agent.Notifications.decline(declineOperation.operation_request);
      const response = await agent.Notifications.feed(0);
      setNotifications(response.notifications);
    }
  };

  const handleNotificationDeleteClick = async (notification) => {
    await agent.Notifications.delete(notification.id);
    const response = await agent.Notifications.feed(0);
    setNotifications(response.notifications);
  };

  const handleNotificationClick = async (notification) => {
    await agent.Notifications.read(notification.id);
    const response = await agent.Notifications.feed(0);
    setNotifications(response.notifications);
  };

  const handleNotificationOpenClick = async (notification) => {
    navigate(notification.link);
    await agent.Notifications.read(notification.id);
    const response = await agent.Notifications.feed(0);
    setNotifications(response.notifications);
  };

  const loadMoreNotifications = async (page) => {
    if (hasMore) {
      const results = await agent.Notifications.feed(page - 1)

      setNotifications([...notifications, ...results.notifications]);

      setIsLoading(false);

      if (results.notifications.length === 0) {
        setHasMore(false);
      }
    }
  };

  return (
    <div>
      <TopBar/>
      <InfiniteScroll
        pageStart={0}
        loadMore={loadMoreNotifications}
        hasMore={hasMore}
        loader={
          <CommonLoader />
        }
      >
        <List sx={{
          width: '100%',
          marginTop: 2,
          paddingLeft: isDesktop && props.leftNavigationOpen ? 37 : 1,
        }}>
          {
            (notifications && notifications.length) || isLoading
              ? notifications.map(notification => {
                return (
                  <>
                    <ListItem
                      alignItems="flex-start"
                      style={{
                        // boxShadow: notification.read ? 'none' : '0px -10px 10px 0px #36c9a2'
                      }}
                      onClick={() => handleNotificationClick(notification)}
                    >
                      <ListItemAvatar>
                        <Link to={`/@${notification.author.username}`}>
                          <Avatar
                            alt={notification.user.username}
                            src={notification.author.imageUrl}
                          />
                        </Link>

                      </ListItemAvatar>
                      <ListItemText
                        primary={getInvitationTypeName(notification.type, translation)}
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{display: 'inline'}}
                              component="span"
                              variant="body2"
                              // color="text.primary"
                            >
                            </Typography>
                            {notification.name}
                          </React.Fragment>
                        }
                      />
                      {
                        (notification?.freak?.context || notification.freakHub?.context)
                          ? <IconButton
                            style={{
                              color: DEFAULT_THEME_COLOR,
                            }}
                            sx={{
                              '&:focus': {
                                outline: 'none'
                              },
                            }}
                          >
                            {getFreakIcon(notification.freak?.context || notification.freakHub?.context)}
                          </IconButton>
                          : null
                      }
                      {
                        (
                          notification.type === NOTIFICATION_TYPES.INVITATION
                          || notification.type === NOTIFICATION_TYPES.REQUEST_JOIN
                        ) && notification.status === 'pending'
                          ? <ButtonGroup
                            sx={{
                              display: 'flex',
                            }}
                            orientation="vertical"
                          >
                            <Button
                              size={'small'}
                              startIcon={<CheckIcon/>}
                              sx={{
                                outline: 'none',
                                '&:focus': {
                                  outline: 'none'
                                },
                                '&:hover': {
                                  color: DEFAULT_THEME_COLOR,
                                  borderColor: DEFAULT_THEME_COLOR,
                                },
                              }}
                              style={{
                                width: 150,
                                backgroundColor: DEFAULT_THEME_COLOR,
                                borderColor: FEED_WEEKLY_COLOR,
                                color: 'white',
                                alignSelf: 'flex-end',
                                'z-index': '0',
                                borderRadius: 4,
                                marginBottom: 5
                              }}
                              variant="outlined"
                              onClick={() => handleNotificationApproveClick(notification)}
                            >
                              {translation.notifications.accept}
                            </Button>
                            <Button
                              size={'small'}
                              startIcon={<CloseIcon/>}
                              sx={{
                                outline: 'none',
                                '&:focus': {
                                  outline: 'none'
                                },
                                '&:hover': {
                                  color: DEFAULT_THEME_COLOR,
                                  borderColor: DEFAULT_THEME_COLOR,
                                },
                              }}
                              style={{
                                width: 150,
                                backgroundColor: FEED_YEARLY_COLOR,
                                borderColor: FEED_YEARLY_COLOR,
                                color: 'white',
                                alignSelf: 'flex-end',
                                'z-index': '0',
                                borderRadius: 4,
                                marginBottom: 2
                              }}
                              variant="outlined"
                              onClick={() => handleNotificationDeclineClick(notification)}
                            >
                              {translation.notifications.decline}
                            </Button>
                          </ButtonGroup>
                          : null
                      }
                    </ListItem>
                    <Grid
                      container
                      spacing={2}
                      style={{
                        paddingBottom: 10,
                        boxShadow: notification.read ? 'none' : '0px 10px 10px 0px #36c9a2'
                      }}
                    >
                      <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                          marginTop: 3,
                          marginLeft: 11,
                          width: '100%',
                        }}>

                        {
                          notification && notification.status !== 'pending' ?
                            <Button
                              size={"small"}
                              variant="outlined"
                              endIcon={<SendIcon/>}
                              sx={{
                                outline: 'none',
                                '&:focus': {
                                  outline: 'none'
                                },
                                '&:hover': {
                                  color: DEFAULT_THEME_COLOR,
                                  borderColor: DEFAULT_THEME_COLOR,
                                },
                              }}
                              style={{
                                color: DEFAULT_THEME_COLOR,
                                borderColor: DEFAULT_THEME_COLOR,
                              }}
                              onClick={() => handleNotificationOpenClick(notification)}
                            >
                              {translation.notifications.show}
                            </Button>
                            : null
                        }

                        <Button
                          size={"small"}
                          variant="outlined"
                          startIcon={<DeleteIcon/>}
                          sx={{
                            outline: 'none',
                            '&:focus': {
                              outline: 'none'
                            },
                            '&:hover': {
                              color: DEFAULT_THEME_COLOR,
                              borderColor: DEFAULT_THEME_COLOR,
                            },
                          }}
                          style={{
                            color: FEED_YEARLY_COLOR,
                            borderColor: FEED_YEARLY_COLOR,
                          }}
                          onClick={() => handleNotificationDeleteClick(notification)}
                        >
                          {translation.notifications.delete}
                        </Button>
                        <div style={{
                          flexGrow: 1,
                          textAlign: 'right',
                          marginRight: 20,
                          fontSize: '0.875rem',
                          color: DEFAULT_THEME_COLOR,
                        }}>
                          {formatDateAgo(notification.createdAt)}
                        </div>
                      </Stack>
                    </Grid>
                    <Divider/>
                  </>
                )
              })
              : <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="70vh"
              >
                <span style={{fontSize: '20px'}}>{translation.notifications.emptyList}</span>
              </Box>
          }
        </List>
      </InfiniteScroll>
    </div>
  );
};

const mapStateToProps = state => ({
  ...state.notifications,
  currentUser: state.common.currentUser,
  context: state.common.context,
  leftNavigationOpen: state.common.leftNavigationOpen,
});

const mapDispatchToProps = dispatch => ({
  onLoad: payload =>
    dispatch({type: NOTIFICATION_PAGE_LOADED, payload}),
  onUnload: () =>
    dispatch({type: NOTIFICATION_PAGE_UNLOADED}),
  onSetPage: (page, payload) =>
    dispatch({type: NOTIFICATION_SET_PAGE, page, payload}),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
