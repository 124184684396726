import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import article from './reducers/article';
import articleList from './reducers/articleList';
import freak from './reducers/freak';
import campaign from './reducers/campaign';
import campaignList from './reducers/campaignList';
import freakEditor from './reducers/freakEditor';
import freakHubEditor from "./reducers/freakHubEditor";
import campaignEditor from './reducers/campaignEditor';
import freakPlacesEditor from './reducers/freakPlacesEditor';
import freakList from './reducers/freakList';
import auth from './reducers/auth';
import common from './reducers/common';
import editor from './reducers/editor';
import home from './reducers/home';
import profile from './reducers/profile';
import settings from './reducers/settings';
import notifications from './reducers/notification';

export default combineReducers({
  article,
  articleList,
  freak,
  campaign,
  campaignList,
  freakEditor,
  freakHubEditor,
  campaignEditor,
  freakPlacesEditor,
  freakList,
  auth,
  common,
  editor,
  home,
  profile,
  settings,
  notifications,
  router: routerReducer
});
