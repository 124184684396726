import React from 'react';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import {DEFAULT_THEME_COLOR} from "../../constants/theme";
import {formatDate} from "../../utils/dateUtils";

const PricesHistoryListComponent = (props) => {

  const calculateStyles = idx => {
    return {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: DEFAULT_THEME_COLOR
      }
    };
  };

  return (
    <>
      <List sx={{ width: '100%', maxWidth: '100%', bgcolor: 'background.paper' }}>
        {
          (props.data || []).map((item, idx) => (
            <>
              <ListItem
                alignItems="flex-start"
                sx={calculateStyles(idx)}
              >
                <Typography
                  variant={idx <= 9 ? 'h5' : 'h7'}
                  sx={{
                    marginTop: 1.5,
                    color: 'grey',
                    marginRight: 2,
                  }}>
                </Typography>
                <ListItemAvatar>
                  <Avatar alt={item.id} src={item.imageUrl || './logo-circle.png'} />
                </ListItemAvatar>
                <Grid container>
                  <Grid container xs={12} sx={{ marginTop: 1, }}>
                    <React.Fragment>
                      {item.shakePrice?.description}
                    </React.Fragment>
                  </Grid>
                  <Grid container xs={12}>
                    <React.Fragment>
                      <span style={{
                        color: 'grey',
                        fontSize: 12,
                      }}>
                        {formatDate(item?.createdAt)}
                      </span>
                    </React.Fragment>
                  </Grid>
                </Grid>

              </ListItem>
              <Divider variant="inset" component="li" />
            </>
          ))
        }
      </List>
    </>
  )
};

export default PricesHistoryListComponent;
