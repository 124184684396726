import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import FreakPreview from '../Freak/FreakPreview';
import useTranslation from "../../customHooks/translations";
import {DEFAULT_BORDER_COLOR, DEFAULT_THEME_COLOR} from "../../constants/theme";
import Button from "@mui/material/Button";
import InfiniteScroll from "react-infinite-scroller";
import agent from "../../agent";
import {FREAKS_SET_PAGE} from "../../constants/actionTypes";
import CommonLoader from "../Loaders/CommonLoader";

const FreakHubList = props => {
  const translation = useTranslation();
  const [freaks, setFreaks] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);

  useEffect(() => {
    setPage(0);
    setFreaks([]);
    setHasMore(true);
    setIsLoading(true);

    loadMoreFreaks(0);
  }, [
    props.tab,
    props.context,
  ]);

  const loadMoreFreaks = async (__page) => {
    if (hasMore) {
      setIsLoading(true);

      const results = await agent.Freaks.forFreakHub(
        page,
        props.context,
        props.device,
        props.forFreakHub,
      );

      setFreaks([...freaks, ...results.freaks]);
      setPage(page + 1);

      setIsLoading(false);

      if (results?.freaks?.length === 0) {
        setHasMore(false);
      }
    }
  };

  if (isLoading === false && freaks?.length === 0) {
    return (
      <div style={{
        minHeight: 150,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}>
        <span style={{fontSize: '15px'}}>
          <Button
            sx={{
              color: DEFAULT_THEME_COLOR,
              borderColor: DEFAULT_BORDER_COLOR,
              'z-index': '0',
              marginLeft: 1,
              marginRight: 1,
              marginTop: 2,
              marginBottom: 2,
              '&:hover': {
                color: DEFAULT_THEME_COLOR,
                borderColor: DEFAULT_THEME_COLOR,
              }
            }}
            variant="outlined"
          >
            {translation.freaks.emptyList}
          </Button>
        </span>
      </div>
    );
  }

  return (
    <>
      <InfiniteScroll
        pageStart={0}
        loadMore={loadMoreFreaks}
        hasMore={hasMore}
        loader={
          <CommonLoader/>
        }
      >
        {
          freaks?.map(freak => {
            return (
              <FreakPreview
                freak={freak}
                key={freak.slug}
              />
            );
          })
        }
      </InfiniteScroll>
    </>
  );
};

const mapStateToProps = state => ({
  ...state.FreakList,
  device: state.common.device,
  currentUser: state.common.currentUser,
});

const mapDispatchToProps = dispatch => ({
  onSetPage: (page, payload) =>
    dispatch({type: FREAKS_SET_PAGE, page, payload})
});

export default connect(mapStateToProps, mapDispatchToProps)(FreakHubList);
