import React, { useEffect, useState } from 'react';
import TopBar from '../TopBar';
import agent from "../../agent";
import useTranslation from "../../customHooks/translations";
import InfiniteScroll from "react-infinite-scroller";
import CommonLoader from "../Loaders/CommonLoader";
import {useMediaQuery} from "react-responsive";
import {connect} from "react-redux";
import FreakHubUsersList from "../List/FreakHubUsersList";
import {useParams} from "react-router-dom";

const FreakHubUsersView = (props) => {
  const translation = useTranslation();
  const {slug} = useParams();

  const [freakHubUsers, setFreakHubUsers] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const isDesktop = useMediaQuery({ minWidth: 992 });

  useEffect(() => {
    setPage(0);
    setFreakHubUsers([]);
    setHasMore(true);
    setIsLoading(true);

    loadMoreFreakHubUsers(0);
  }, []);

  const loadMoreFreakHubUsers = async (__page) => {
    if (hasMore) {
      const results = await agent.FreakHubs.getUsers(page, slug);

      setFreakHubUsers([...freakHubUsers, ...results.moderators, ...results.participants]);
      setPage(page + 1);
      setIsLoading(false);

      if (results?.participants?.length === 0) {
        setHasMore(false);
      }
    }
  };
  const paddingLeft = (isDesktop && props.leftNavigationOpen) ? 300 : (isDesktop && !props.leftNavigationOpen ? 75 : 0);

  return (
    <div style={{
      paddingLeft,
    }}>
      <TopBar/>
      <InfiniteScroll
        pageStart={0}
        loadMore={loadMoreFreakHubUsers}
        hasMore={hasMore}
        loader={
          <CommonLoader />
        }
      >
        <FreakHubUsersList
          title={translation.hubUsers.title}
          data={freakHubUsers}
        />
      </InfiniteScroll>

    </div>
  )
};

const mapStateToProps = state => ({
  leftNavigationOpen: state.common.leftNavigationOpen,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FreakHubUsersView);
