import React, { useEffect, useState } from 'react';
import TopBar from '../TopBar';
import agent from "../../agent";
import useTranslation from "../../customHooks/translations";
import InfiniteScroll from "react-infinite-scroller";
import CommonLoader from "../Loaders/CommonLoader";
import {useMediaQuery} from "react-responsive";
import {connect} from "react-redux";
import FeedListComponent from "../List/FeedListComponent";

const TopFeedsView = (props) => {
  const translation = useTranslation();

  const [topFeeds, setTopFeeds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const isDesktop = useMediaQuery({ minWidth: 992 });

  useEffect(() => {
    setTopFeeds([]);
    setIsLoading(true);

    loadTopFeeds();
  }, []);

  const loadTopFeeds = async () => {
    const results = await agent.Feeds.getTopFeeds();

    setTopFeeds([...topFeeds, ...results]);
    setIsLoading(false);
  };
  const paddingLeft = (isDesktop && props.leftNavigationOpen) ? 300 : (isDesktop && !props.leftNavigationOpen ? 75 : 0);

  return (
    <div style={{
      paddingLeft,
    }}>
      <TopBar/>
      <FeedListComponent
        title={translation.topFeeds.title}
        data={topFeeds}
      />
    </div>
  )
};

const mapStateToProps = state => ({
  leftNavigationOpen: state.common.leftNavigationOpen,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TopFeedsView);
