import React, { useEffect, useState } from 'react';
import TopBar from '../TopBar';
import agent from "../../agent";
import useTranslation from "../../customHooks/translations";
import InfiniteScroll from "react-infinite-scroller";
import CommonLoader from "../Loaders/CommonLoader";
import {useMediaQuery} from "react-responsive";
import {connect} from "react-redux";
import PricesHistoryListComponent from "../List/PricesHistoryListComponent";

const ShakePricesHistoryView = (props) => {
  const translation = useTranslation();

  const [pricesHistory, setPricesHistory] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const isDesktop = useMediaQuery({ minWidth: 992 });

  useEffect(() => {
    setPage(0);
    setPricesHistory([]);
    setHasMore(true);
    setIsLoading(true);

    loadMorePricesHistory(0);
  }, []);

  const loadMorePricesHistory = async (__page) => {
    if (hasMore) {
      const results = await agent.Price.getHistory(page);

      setPricesHistory([...pricesHistory, ...results]);
      setPage(page + 1);
      setIsLoading(false);

      if (results.length === 0) {
        setHasMore(false);
      }
    }
  };
  const paddingLeft = (isDesktop && props.leftNavigationOpen) ? 300 : (isDesktop && !props.leftNavigationOpen ? 75 : 0);

  return (
    <div style={{
      paddingLeft,
    }}>
      <TopBar/>
      <InfiniteScroll
        pageStart={0}
        loadMore={loadMorePricesHistory}
        hasMore={hasMore}
        loader={
          <CommonLoader />
        }
      >
        <PricesHistoryListComponent
          title={translation.pricesHistory.title}
          data={pricesHistory}
        />
      </InfiniteScroll>

    </div>
  )
};

const mapStateToProps = state => ({
  leftNavigationOpen: state.common.leftNavigationOpen,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ShakePricesHistoryView);
