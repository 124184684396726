import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";

import CommonLoader from "../Loaders/CommonLoader";
import List from "@mui/material/List";
import agent from "../../agent";
import useTranslation from "../../customHooks/translations";
import InfiniteScroll from "react-infinite-scroller";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {DEFAULT_BORDER_COLOR, DEFAULT_THEME_COLOR} from "../../constants/theme";
import {FREAK_HUB_TYPE_MY} from "../../constants/commonConstants";
import Grid from "@mui/material/Grid";


const ListHubsComponent = (props) => {
  const translation = useTranslation();
  const navigate = useNavigate();

  const [hubs, setHubs] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);

  useEffect(() => {
    setPage(0);
    setHubs([]);
    setHasMore(true);
    setIsLoading(true);

    loadMoreHubs(0, props.type);
  }, [props.type]);

  const loadMoreHubs = async (__page) => {
    if (hasMore) {
      setIsLoading(true);

      const results = props.type === FREAK_HUB_TYPE_MY
        ? await agent.FreakHubs.feed(page, props.context)
        : await agent.FreakHubs.explore(page, props.context);

      setHubs([...hubs, ...results.freakHubs]);
      setPage(page + 1);
      setIsLoading(false);

      if (results.freakHubs?.length === 0) {
        setHasMore(false);
      }
    }
  };

  if (isLoading === false && hubs.length === 0) {
    return (
      <div style={{
        minHeight: 150,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}>
        <span style={{fontSize: '15px'}}>
          <Button
            sx={{
              color: DEFAULT_THEME_COLOR,
              borderColor: DEFAULT_BORDER_COLOR,
              'z-index': '0',
              marginLeft: 1,
              marginRight: 1,
              marginTop: 2,
              marginBottom: 2,
              '&:hover': {
                color: DEFAULT_THEME_COLOR,
                borderColor: DEFAULT_THEME_COLOR,
              }
            }}
            variant="outlined"
          >
            {translation.hubs.emptyList}
          </Button>
        </span>
      </div>
    );
  }

  return (
    <div>
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMoreHubs}
          hasMore={hasMore}
          loader={
            <CommonLoader/>
          }
        >
          <List sx={{width: '100%', maxWidth: '100%', bgcolor: 'background.paper'}}>
            {
              hubs.map(hub => (
                <div className="article-preview">
                  <Card sx={{margin: 2, borderRadius: 5}}>
                    <CardMedia
                      component="img"
                      alt="green iguana"
                      height="200"
                      image={hub.imageUrl || '/logo.png'}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {hub.name}
                      </Typography>
                      <Typography variant="body2" sx={{color: 'text.secondary'}}>
                        {hub.description}
                      </Typography>
                    </CardContent>
                    <CardActions
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center', // Optional if needed for vertical alignment
                      }}>
                      <Button
                        size="md"
                        variant="contained"
                        style={{
                          width: 150,
                          backgroundColor: DEFAULT_THEME_COLOR,
                          color: 'white',
                          alignSelf: 'flex-end',
                          'z-index': '0',
                          borderRadius: 4,
                          marginBottom: 5,
                        }}
                        onClick={() => navigate(`/freak-hub/${hub.slug}`, {
                          state: {
                            type: props.type
                          }
                        })}
                      >
                        {translation.hubs.view}
                      </Button>
                      <Grid
                        container
                        xs={6}
                        onClick={() => navigate(`/freak-hub/${hub.slug}/users`)}
                        sx={{
                          justifyContent: 'flex-end', // Ensures the Grid is on the right
                          alignItems: 'center', // Optional for better alignment
                          display: 'flex',
                        }}
                      >
                        <Grid
                          item
                          sx={{
                            fontWeight: 'bold',
                            marginRight: 2,
                          }}
                        >
                          {hub.participants?.length + 1}
                        </Grid>
                        <Grid item sx={{
                          fontSize: 13,
                        }}>
                          {translation.hubDetails.usersCount}
                        </Grid>
                      </Grid>
                    </CardActions>
                  </Card>
                </div>
              ))
            }
          </List>
        </InfiniteScroll>
      </div>
  )
};

const mapStateToProps = state => ({
  context: state.common.context,
  currentUser: state.common.currentUser,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ListHubsComponent);
