import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import TopBar from "../TopBar";
import useTranslation from "../../customHooks/translations";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { DEFAULT_THEME_COLOR } from "../../constants/theme";
import agent from "../../agent";
import FreakCampaignsPreview from "./FreakCampaignsPreview";
import {CAMPAIGN_SET_PAGE} from "../../constants/actionTypes";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import SwipeVerticalIcon from "@mui/icons-material/SwipeVertical";
import {useNavigate} from "react-router-dom";

const FreakCampaigns = (props) => {
  const translation = useTranslation();
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState([]);

  const device = props.device;
  const context = props.context;

  useEffect(() => {
    const fetchData = async () => {
      // @todo pages

      const { campaigns, campaignsCount } = await agent.Campaigns.all(
        0, context, device, props.sortByLikesFilter, props.sortByDateFilter,
      );

      setCampaigns(campaigns);

      props.onSetPage(0, { campaigns, campaignsCount });
    };
    fetchData();
  }, []);

 useEffect(() => {
   setCampaigns(props.campaigns);
 }, [
   props.campaigns
 ]);

 const getCampaignsComponents = campaigns => {
   if (device === 'desktop') {
     return (
       <div
         className="container page"
         style={{
           display: 'flex',
           justifyContent: 'center'
         }}
       >
         <div
           className="row"
           style={{
             maxWidth: 500
           }}
         >
           {
             campaigns?.map(campaign => (
               <FreakCampaignsPreview campaign={campaign} key={campaign.slug} />
             ))
           }
         </div>
       </div>
     );
   }

   return campaigns?.map(campaign => (<FreakCampaignsPreview campaign={campaign} key={campaign.slug} />))
 };

  return (
    <>
      <TopBar/>

      <Box
        display="flex"
        textAlign="center"
        alignItems="center"
        justifyContent="center"
        marginTop="15px"
      >
        <Grid container>
          <Grid item xs={12}>
            {/*<h4 className="reward">{translation.freakCampaigns.contact}</h4>*/}
            <h4 className="reward">{translation.freakCampaigns.everyDayWin}</h4>
          </Grid>
          {/*<Grid item xs={12}>*/}
          {/*  <Button*/}
          {/*    component="a"*/}
          {/*    href="mailto:freakland.app@gmail.com"*/}
          {/*    target="_blank"*/}
          {/*    rel="noopener"*/}
          {/*    aria-label="Navigate"*/}
          {/*    endIcon={<MailOutlineIcon sx={{ color: DEFAULT_THEME_COLOR }}/>}*/}
          {/*    sx={{ color: 'white'}}*/}
          {/*  >*/}
          {/*    {translation.freakCampaigns.contactButton}*/}
          {/*  </Button>*/}
          {/*</Grid>*/}
          <Grid item xs={12}>
            <Button
              aria-label="Navigate"
              endIcon={<SwipeVerticalIcon sx={{ color: DEFAULT_THEME_COLOR }}/>}
              sx={{ color: 'white'}}
              onClick={() => navigate('/shake-it')}
            >
              {translation.freakCampaigns.winPrice}
            </Button>
          </Grid>
        </Grid>
      </Box>

      {
        campaigns?.length
          ? getCampaignsComponents(campaigns)
          : <Box
            display="flex"
            textAlign="center"
            alignItems="center"
            justifyContent="center"
            height="70vh"
          >
            <Grid container>
              <Grid item xs={12}>
                <span style={{ fontSize: '20px' }}>{translation.freakCampaigns.noCampaigns}</span>
              </Grid>
            </Grid>
          </Box>
      }
    </>
  )
};

const mapStateToProps = state => ({
  ...state.campaignList,
  device: state.common.device,
  sortByLikesFilter: state.common.sortByLikesFilter,
  sortByDateFilter: state.common.sortByDateFilter,
  filterByNameFilter: state.common.filterByNameFilter,
  filterByDateFromFilter: state.common.filterByDateFromFilter,
  filterByDateToFilter: state.common.filterByDateToFilter,
  filterByStatusFilter: state.common.filterByStatusFilter,
  filterByParticipantFilter: state.common.filterByParticipantFilter,
});

const mapDispatchToProps = dispatch => ({
  onSetPage: (page, payload) =>
    dispatch({ type: CAMPAIGN_SET_PAGE, page, payload })
});

export default connect(mapStateToProps, mapDispatchToProps)(FreakCampaigns);
