import React from "react";

import ImageViewComponent from "../components/ImageView/ImageViewComponent";
import CarouselComponent from "../components/Embed/CarouselComponent";
import {
  CAMPAIGN_TYPE_CAMPAIGN,
  CAMPAIGN_TYPE_PROMOTION,
  FEED_DAILY,
  FEED_MONTHLY,
  FEED_WEEKLY,
  FEED_YEARLY,
  FREAK_PLACE_TYPE_FISHING_COMMERCIAL,
  FREAK_PLACE_TYPE_FISHING_PZW,
  FREAK_PLACE_TYPE_FISHING_WHITE_CARD_ONLY,
  FREAK_PLACE_TYPE_WINTER_SPORTS_SKI_RESORT,
  FREAK_PLACE_TYPE_WINTER_SPORTS_SNOWPARK,
  FREAK_PLACE_TYPE_WOF_MOTOCROSS, FREAK_TYPE_FREAK_HUB,
  STATUS_CANCELED,
  STATUS_FINISHED,
  STATUS_IN_PROGRESS,
  STATUS_PLANNED
} from "../constants/commonConstants";
import {
  DEFAULT_THEME_COLOR,
  FEED_DAILY_COLOR, FREAK_HUB_COLOR
} from "../constants/theme";

export const getPreviewCarousel = (item) => {
  if (item?.images?.length) {
    return (
      <ImageViewComponent images={item.images} />
    );
  }

  if (item?.linksYoutube?.length) {
    return (
      <CarouselComponent youtubeEmbeds={item.linksYoutube.map(link => link.url)} />
    );
  }

  if (item?.linksFacebook?.length) {
    return (
      <CarouselComponent facebookEmbeds={item.linksFacebook.map(link => link.url)} />
    );
  }

  if (item?.linksInstagram?.length) {
    return (
      <CarouselComponent instagramEmbeds={item.linksInstagram.map(link => link.url)} />
    );
  }
};

export const getStatusMessage = (item, translation) => {
  if (!item?.status) {
    return translation.freaks.status.finished;
  }
  switch (item.status) {
    case STATUS_IN_PROGRESS:
      return translation.freaks.status.inProgress;
    case STATUS_FINISHED:
      return translation.freaks.status.finished;
    case STATUS_PLANNED:
      return translation.freaks.status.planned;
    case STATUS_CANCELED:
      return translation.freaks.status.canceled;
    default:
      return translation.freaks.status.finished;
  }
};

export const getCampaignTypeLabel = campaign => {
  if (campaign.type === CAMPAIGN_TYPE_CAMPAIGN) {
    return 'campaign';
  }
  if (campaign.type === CAMPAIGN_TYPE_PROMOTION) {
    return 'promotion';
  }
};

export const getCampaignTypeLabelColor = campaign => {
  if (campaign.type === CAMPAIGN_TYPE_CAMPAIGN) {
    return 'orange';
  }
  if (campaign.type === CAMPAIGN_TYPE_PROMOTION) {
    return 'pink';
  }
};

export const getVisibilityLevelColor = item => {
  if (item.public === undefined) {
    return 'grey';
  }

  if (item.type === FEED_DAILY) {
    return FEED_DAILY_COLOR;
  }
  if (item.type === FREAK_TYPE_FREAK_HUB) {
    return FREAK_HUB_COLOR;
  }
  if (item.type === FEED_WEEKLY) {
    return 'weekly';
  }
  if (item.type === FEED_MONTHLY) {
    return 'monthly';
  }
  if (item.type === FEED_YEARLY) {
    return 'yearly';
  }

  return item.public ? DEFAULT_THEME_COLOR : 'grey';
};

export const getFreakPlaceTypeBackgroundColor = freakPlaceType => {
  if (freakPlaceType.name === FREAK_PLACE_TYPE_FISHING_PZW) {
    return '#031461';
  }
  if (freakPlaceType.name === FREAK_PLACE_TYPE_FISHING_COMMERCIAL) {
    return 'orange';
  }

  if (freakPlaceType.name === FREAK_PLACE_TYPE_FISHING_WHITE_CARD_ONLY) {
    return 'white';
  }

  if (freakPlaceType.name === FREAK_PLACE_TYPE_WOF_MOTOCROSS) {
    return 'red';
  }

  if (freakPlaceType.name === FREAK_PLACE_TYPE_WINTER_SPORTS_SKI_RESORT) {
    return '#89cff0';
  }

  if (freakPlaceType.name === FREAK_PLACE_TYPE_WINTER_SPORTS_SNOWPARK) {
    return '#899cf0';
  }
};

export const getFreakPlaceTypeColor = freakPlaceType => {
  if (freakPlaceType.name === FREAK_PLACE_TYPE_FISHING_PZW ||
    freakPlaceType.name === FREAK_PLACE_TYPE_FISHING_COMMERCIAL ||
    freakPlaceType.name === FREAK_PLACE_TYPE_WOF_MOTOCROSS ||
    freakPlaceType.name === FREAK_PLACE_TYPE_WINTER_SPORTS_SKI_RESORT ||
    freakPlaceType.name === FREAK_PLACE_TYPE_WINTER_SPORTS_SNOWPARK) {
    return 'white';
  }

  if (freakPlaceType.name === FREAK_PLACE_TYPE_FISHING_WHITE_CARD_ONLY) {
    return 'black';
  }
};

export const getVisibilityLevel = item => {
  if (item.public === undefined) {
    return null;
  }

  if (item.type === FEED_DAILY) {
    return 'daily';
  }
  if (item.type === FEED_WEEKLY) {
    return 'weekly';
  }
  if (item.type === FEED_MONTHLY) {
    return 'monthly';
  }
  if (item.type === FEED_YEARLY) {
    return 'yearly';
  }
  if (item.type === FREAK_TYPE_FREAK_HUB) {
    return 'freak_hub';
  }

  return item.public ? 'public' : 'private';
};

export const capitalizeFirstLetter = value => {
  if (!value) return value;
  return value.charAt(0).toUpperCase() + value.slice(1);
};
