import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import useTranslation from "../../customHooks/translations";
import TopBar from "../TopBar";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {DEFAULT_BORDER_COLOR, DEFAULT_THEME_COLOR} from "../../constants/theme";
import {FREAK_HUB_TYPE_MY} from "../../constants/commonConstants";
import agent from "../../agent";
import {formatDateShort} from "../../utils/dateUtils";
import LockIcon from '@mui/icons-material/Lock';
import {connect} from "react-redux";
import FreakHubList from "./FreakHubList";
import CommonLoader from "../Loaders/CommonLoader";
import {isFreakHubParticipantOrAuthor} from "../../utils/freakUtils";

const FreakHubDetails = (props) => {
  const translation = useTranslation();
  const {slug} = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const type = location?.state?.type || null;

  const [hubDetails, setHubDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [joinButtonMessage, setJoinButtonMessage] = useState(translation.freaks.operations.join);
  const [joinButtonDisabled, setJoinButtonDisabled] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const results = await agent.FreakHubs.get(slug);

      setHubDetails(results.freakHub);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const handleRequestJoinClick = freakHub => {
    agent.FreakHubs.join(freakHub.id);
    setJoinButtonMessage(translation.freaks.operations.requested);
    setJoinButtonDisabled(true);
  };

  return (
    <>
      <TopBar/>
      {
        isLoading
          ? <CommonLoader/>
          : <div>
            <Box>
              <div className="container">
                <div className="row">
                  <div className="col-xs-12 col-md-10 offset-md-1">
                    <Box
                      sx={{
                        background: '#f3f3f3',
                        backgroundImage: hubDetails.backgroundImageUrl ? `url("${hubDetails.backgroundImageUrl}")` : '',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        borderRadius: 5,
                        height: '200px',
                        display: 'flex', // Enable flexbox
                        flexDirection: 'column', // Align children vertically
                        alignItems: 'center', // Center children horizontally
                        justifyContent: 'center', // Center children vertically
                      }}
                    >
                      <Avatar
                        sx={{
                          width: '100px',
                          height: '100px',
                          borderRadius: 5,
                          marginTop: 5,
                          marginLeft: 'auto',
                          marginRight: 'auto'
                        }}
                        alt={hubDetails.id}
                        src={hubDetails?.imageUrl}
                      />
                      <Typography variant={'h7'} sx={{
                        color: 'white',
                      }}>
                        {hubDetails.name}
                      </Typography>
                      <p style={{
                        color: 'grey',
                        fontSize: 13,
                      }}>{translation.hubDetails.createdAt}: {formatDateShort(hubDetails.createdAt)}</p>

                      {
                        !isFreakHubParticipantOrAuthor(props.currentUser?.id, hubDetails)
                          ? <Button
                            disabled={joinButtonDisabled}
                            variant="outlined"
                            style={{
                              backgroundColor: DEFAULT_THEME_COLOR,
                              color: 'white',
                              borderColor: DEFAULT_THEME_COLOR,
                              'z-index': '0',
                              borderRadius: 4,
                              marginBottom: 10,
                              outline: 'none',
                            }}
                            onClick={() => handleRequestJoinClick(hubDetails)}
                          >
                            {joinButtonMessage}
                          </Button>
                          : null
                      }
                    </Box>

                    <Box sx={{
                      marginTop: 1,
                      marginLeft: 1,
                      marginBottom: 1,
                    }}>
                      <Grid
                        container
                        spacing={2}
                        alignItems="left"
                        sx={{
                          marginTop: 1,
                          marginBottom: 3,
                        }}
                      >
                      </Grid>

                      <Grid
                        container
                        spacing={0}
                        alignItems="left"
                        sx={{
                          marginTop: 4,
                          marginBottom: 3,
                        }}
                      >
                        <Grid container xs={6}>
                          <Grid item sx={{
                            fontWeight: 'bold',
                            marginRight: 2,
                          }}>
                            {hubDetails.freaksCount}
                          </Grid>
                          <Grid
                            item
                            sx={{
                              marginTop: 0.35,
                              fontSize: 13,
                            }}
                          >
                            {translation.hubDetails.freaksCount}
                          </Grid>
                        </Grid>
                        <Grid container xs={6}
                              onClick={() => navigate(`/freak-hub/${hubDetails.slug}/users`)}
                        >
                          <Grid
                            item
                            sx={{
                              fontWeight: 'bold',
                              marginRight: 2,
                            }}
                          >
                            {hubDetails.usersCount}
                          </Grid>
                          <Grid item sx={{
                            marginTop: 0.35,
                            fontSize: 13,
                          }}>
                            {translation.hubDetails.usersCount}
                          </Grid>
                        </Grid>
                      </Grid>

                      <p
                        style={{
                          color: 'grey',
                          textAlign: 'center'
                        }}>
                        {hubDetails.description}
                      </p>
                    </Box>
                    <br/>
                  </div>
                </div>
              </div>
            </Box>

            {
              isFreakHubParticipantOrAuthor(props.currentUser?.id, hubDetails)
                ? <div className="container">
                  <div className="row">
                    <div className="col-xs-12 col-md-10 offset-md-1" style={{ marginBottom: 50 }}>
                      <FreakHubList
                        tab={'forFreakHub'}
                        context={props.context}
                        forFreakHub={hubDetails.slug}
                      />
                    </div>
                  </div>
                </div>
                : <div style={{
                  minHeight: 150,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                }}>
                    <span style={{fontSize: '15px'}}>
                      <Button
                        sx={{
                          color: DEFAULT_THEME_COLOR,
                          borderColor: DEFAULT_BORDER_COLOR,
                          'z-index': '0',
                          marginLeft: 1,
                          marginRight: 1,
                          marginTop: 2,
                          marginBottom: 2,
                          '&:hover': {
                            color: DEFAULT_THEME_COLOR,
                            borderColor: DEFAULT_THEME_COLOR,
                          }
                        }}
                        startIcon={<LockIcon/>}
                        variant="outlined"
                      >
                        {translation.hubs.privateView}
                      </Button>
                    </span>
                </div>
            }
          </div>
      }
    </>
  )
};

const mapStateToProps = state => ({
  context: state.common.context,
  currentUser: state.common.currentUser,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FreakHubDetails);
