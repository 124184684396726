import React, { useState } from 'react';

import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';

import { store } from './store';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import * as firebaseServiceWorker from '../src/notifications/firebaseServiceWorker';
import { LanguageContextProvider } from "./contexts/LanguageContext";
import { injectSpeedInsights } from '@vercel/speed-insights';
import { inject } from '@vercel/analytics';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Auth0Provider } from '@auth0/auth0-react';

injectSpeedInsights();
inject();

const renderReactDom = () => {
  const lightTheme = createTheme({
    palette: {
      mode: 'light',
    },
  });

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  const root = createRoot(document.getElementById('root'));

  const ThemeSwitcher = () => {
    const [themeMode, setThemeMode] = useState('dark'); // 'light' or 'dark'

    const toggleTheme = userTheme => {
      if (userTheme) {
        setThemeMode(userTheme);
      } else {
        setThemeMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
        return themeMode === 'light' ? 'dark' : 'light';
      }
    };

    return (
      <ThemeProvider theme={themeMode === 'light' ? lightTheme : darkTheme}>
        <CssBaseline />
        <App handleTheme={toggleTheme} />
      </ThemeProvider>
    );
  };

  root.render(
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      }}
    >
      <Provider store={store}>
        <BrowserRouter>
          <LanguageContextProvider>
            <ThemeSwitcher />
          </LanguageContextProvider>
        </BrowserRouter>
      </Provider>
    </Auth0Provider>
  );
};

if (window.cordova) {
  document.addEventListener('deviceready', () => {
    renderReactDom();

    // Define the function here to ensure the Cordova environment is ready
    window.handleWatchData = function(data) {
      console.log("Received data from watchOS: " + data);
      // @todo Handle the data, update the UI, or perform any actions
    };
  }, false);
} else {
  renderReactDom();

  serviceWorker.register();
  firebaseServiceWorker.register();
}
