import React from 'react';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {Link, useNavigate} from "react-router-dom";
import useTranslation from "../../customHooks/translations";
import Grid from "@mui/material/Grid";
import {DEFAULT_THEME_COLOR, FEED_YEARLY_COLOR} from "../../constants/theme";
import Button from "@mui/material/Button";

const FeedListComponent = (props) => {
  const navigate = useNavigate();
  const translation = useTranslation();

  const calculateStyles = idx => {
    return {
      cursor: 'pointer',
    };
  };

  const getPlaceIcon = idx => {
    if (idx === 0) {
      return '🏆';
    }
    if (idx === 1) {
      return '🥈'
    }
    if (idx === 2) {
      return '🥉';
    }
  };

  return (
    <>
      <List sx={{ width: '100%', maxWidth: '100%', bgcolor: 'background.paper' }}>
        {
          (props.data || []).map((item, idx) => (
            <>
              <ListItem
                alignItems="flex-start"
                sx={calculateStyles(idx)}
                onClick={() => {}}
              >
                <Typography
                  variant={idx <= 9 ? 'h5' : 'h7'}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: 40,
                    marginTop: 1.5,
                    color: 'grey',
                    marginRight: 2,
                  }}>
                  {getPlaceIcon(idx)}
                </Typography>
                <ListItemAvatar>
                  <Avatar alt={item.id} src={item.thumbnail || './icon-48x48.png'} />
                </ListItemAvatar>
                <Grid container>
                  <Grid container xs={12} sx={{ marginTop: 1, }}>
                    <React.Fragment>
                      {item.forHandle}
                      <Avatar
                        alt={item.id}
                        sx={{
                          width: 14,
                          height: 14,
                          marginLeft: 2,
                          marginRight: 0.5,
                          marginTop: 0.5,
                        }}
                        src={'./icon-48x48.png'}
                      />
                      {item.userCount}
                    </React.Fragment>
                  </Grid>
                  <Grid container xs={12}>
                    <React.Fragment>
                      <span style={{
                        color: 'grey',
                        fontSize: 12,
                      }}>
                        {`${item.description}`}
                      </span>
                    </React.Fragment>
                  </Grid>
                  <Grid container xs={12}>
                    <Button
                      variant="outlined"
                      style={{
                        backgroundColor: DEFAULT_THEME_COLOR,
                        color: 'white',
                        borderColor: 'white',
                        'z-index': '0',
                        borderRadius: 4,
                        outline: 'none',
                        margin: 5,
                      }}
                      onClick={() => navigate('/settings', {
                        state: {
                          youtube: item.forHandle
                        }
                      })}
                    >
                      Feed
                    </Button>
                    <Button
                      variant="outlined"
                      style={{
                        backgroundColor: FEED_YEARLY_COLOR,
                        color: 'white',
                        borderColor: FEED_YEARLY_COLOR,
                        'z-index': '0',
                        borderRadius: 4,
                        outline: 'none',
                        margin: 5,
                      }}
                      component={Link}
                      target="_blank"
                      rel="noopener"
                      to={`https://www.youtube.com/${item.forHandle}`}
                      aria-label="subscribe"
                    >
                      {translation.feeds.subscribe}
                    </Button>
                  </Grid>
                </Grid>

              </ListItem>
              <Divider variant="inset" component="li" />
            </>
          ))
        }
      </List>
    </>
  )
};

export default FeedListComponent;
