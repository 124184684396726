import React, { useEffect } from 'react';

const AdsComponent = (props) => {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.warn('Cannot place Google Add');
    }
  }, []);

  return (
    <>
      <ins className="adsbygoogle"
           style={{ display: "block" }}
           data-ad-client="ca-pub-9162222546310478"
           data-ad-slot="5050436744"
           data-ad-format="auto"
           data-full-width-responsive="true">
      </ins>
    </>
  );
};

export default AdsComponent;
