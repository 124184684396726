export const formatDateAgo = (date) => {
  const now = new Date();
  if (!date) {
    return ''
  }
  const diff = now - new Date(date);

  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 1) {
    return `${days}d`;
  } else if (hours > 1) {
    return `${hours}h`;
  } else if (minutes > 1) {
    return `${minutes}m`;
  } else {
    return `${seconds}s`;
  }
};

export const calculateDuration = (startedAt, finishedAt) => {
  const start = new Date(startedAt);
  const end = new Date(finishedAt);

  const diff = end - start;

  // Convert milliseconds to hours, minutes, and seconds
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff %  (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((diff % (1000 * 60)) / 1000);

  // Construct the formatted duration string
  let formattedDuration = '';
  if (days > 0) {
    formattedDuration += `${days}d`;
  }
  if (hours > 0) {
    formattedDuration += ` ${hours}h`;
  }
  if (minutes > 0) {
    formattedDuration += ` ${minutes}m`;
  }
  if (seconds > 0) {
    formattedDuration += ` ${seconds}s`;
  }

  return formattedDuration.trim();
};

export const formatDateShort = (date) => {
  const originalDate = new Date(date);

  const defaultOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };

  return originalDate.toLocaleDateString('en-GB', defaultOptions);
};

export const formatDate = (date, useSeconds) => {
  const originalDate = new Date(date);

  const defaultOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };

  if (useSeconds) {
    Object.assign(defaultOptions, { second: '2-digit' });
  }

  return originalDate.toLocaleDateString('en-GB', defaultOptions);
};

export const formatCurrentDate = () => {
  const now = new Date();

  // Get individual date and time components
  const day = String(now.getDate()).padStart(2, '0');
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const year = String(now.getFullYear()).slice(2); // Get last two digits of year
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  // Concatenate components to form the desired format
  const formattedTime = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

  return formattedTime;
};
