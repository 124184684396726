import React, { useEffect, useState } from 'react';
import TopBar from "../TopBar";
import agent from "../../agent";
import CommonLoader from "../Loaders/CommonLoader";
import ShakeAndFlipImage from "./ShakeAndFlipImage";
import useTranslation from "../../customHooks/translations";
import AdsComponent from "../AdsComponent";

const ShakeItView = () => {
  const [isShaking, setIsShaking] = useState(false);
  const [shaked, setShaked] = useState(false);
  const [alreadyShaked, setAlreadyShaked] = useState(false);
  const [permissionGranted, setPermissionGranted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAbleToShake, setIsAbleToShake] = useState(false);
  const [shakePrice, setShakePrice] = useState(null);

  const translation = useTranslation();

  // Check if the user is able to shake
  useEffect(() => {
    const checkIfUserIsAbleToShake = async () => {
      const results = await agent.Shake.check();

      setIsAbleToShake(results.isAble);
      setShakePrice(results.shakePrice);
      setIsLoading(false);
    };

    checkIfUserIsAbleToShake();
  }, []);

  // Fetch price only once after the first shake
  useEffect(() => {
    const fetchPrice = async () => {
      if (shaked && !alreadyShaked) {
        const results = await agent.Shake.shake();
        setShakePrice(results.shakePrice);
        setAlreadyShaked(true);
      }
    };

    fetchPrice();
  }, [shaked]);

  // Handle shaking through device motion
  useEffect(() => {
    const SHAKE_THRESHOLD = 15;
    let lastX = 0,
      lastY = 0,
      lastZ = 0;
    let shakeTimeout = null;

    const handleDeviceMotion = (event) => {
      if (!event.accelerationIncludingGravity) return;

      const { x, y, z } = event.accelerationIncludingGravity;
      if (!x || !y || !z) return;

      const deltaX = x - lastX;
      const deltaY = y - lastY;
      const deltaZ = z - lastZ;

      const accelerationMagnitude = Math.sqrt(deltaX ** 2 + deltaY ** 2 + deltaZ ** 2);

      if (accelerationMagnitude > SHAKE_THRESHOLD) {
        setIsShaking(true);
        setShaked(true);

        if (shakeTimeout) clearTimeout(shakeTimeout);
        shakeTimeout = setTimeout(() => setIsShaking(false), 1000);
      }

      lastX = x;
      lastY = y;
      lastZ = z;
    };

    const initDeviceMotionListener = async () => {
      if (
        DeviceMotionEvent &&
        typeof DeviceMotionEvent.requestPermission === 'function'
      ) {
        try {
          const permissionState = await DeviceMotionEvent.requestPermission();
          if (permissionState === 'granted') {
            setPermissionGranted(true);
            window.addEventListener('devicemotion', handleDeviceMotion);
          } else {
            setErrorMessage('Permission to access device motion was denied.');
          }
        } catch (error) {
          setErrorMessage('Error requesting device motion permission.');
        }
      } else {
        setPermissionGranted(true);
        window.addEventListener('devicemotion', handleDeviceMotion);
      }
    };

    initDeviceMotionListener();

    return () => {
      window.removeEventListener('devicemotion', handleDeviceMotion);
      if (shakeTimeout) clearTimeout(shakeTimeout);
    };
  }, []);

  return (
    <div>
      <TopBar />
      {isLoading ? (
        <CommonLoader />
      ) : isAbleToShake ? (
        <div style={{ padding: '20px', textAlign: 'center' }}>
          <h2>{translation.shake.shakeToWin}</h2>
          {!permissionGranted && errorMessage && (
            <p style={{ color: 'red' }}>{errorMessage}</p>
          )}
          {permissionGranted ? (
            <ShakeAndFlipImage
              price={shakePrice?.description}
              isShaking={isShaking}
            />
          ) : (
            <p>{translation.shake.permissions}</p>
          )}
        </div>
      ) : shakePrice ? (
        <div className="shake-it">
          <div className="message">{translation.shake.win}</div>
          <h4 className="reward">{shakePrice?.description}</h4>
          <div className="note">{translation.shake.onlyOnePerDay}</div>
          <div className="note">{translation.shake.visitShop}</div>
          <div>
            <a href="http://shop.freak-land.com/" target="_blank" rel="noopener noreferrer">
            <img
              src={'./freakland-shop-v2.png'}
              width={'200px'}
              height={'200px'}
              alt={`Image`}
            />
            </a>
          </div>
          <AdsComponent />
        </div>
      ) : null}
    </div>
  );
};

export default ShakeItView;